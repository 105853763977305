<template>
  <div>
    <b-modal
        id="modal-1"
        v-model="visibleModal"
        title="Tworzenie darmowego zamówienia"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">

      <div class="form-group">
        <ecat-multiselect
            :set-value="form.userId"
            @change="value => form.userId = value"
            save-id="id"
            label="Użytkownik"
            placeholder="Wybierz użytkownika"
            fetch-one-url="/user"
            load-url="/user/list/pagination"
            query-url="/user/by-email"
            param="email"
            :custom-label="value => value.email"
            :show-labels="false"
            :class="{ 'is-invalid': submitted && $v.form.userId.$error }"
        />

        <div v-if="!$v.form.userId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <label>Wariacja produktu</label>
        <vue-multiselect
            :value="form.variation"
            @select="value => selectVariation(value)"
            :options="variations ? variations : []"
            :custom-label="value => getVariationLabel(value)"
            placeholder="Wybierz wariacje"
            :show-labels="false"
            :class="{ 'is-invalid': submitted && $v.form.variationId.$error }">
          <span slot="noOptions">{{ $t('message.list-is-empty')}}</span>
        </vue-multiselect>

        <div v-if="!$v.form.variationId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group" v-if="shouldSelectShop() && form.userId">
        <ecat-multiselect
            :set-value="form.shopId"
            @change="value => form.shopId = value"
            save-id="id"
            view-id="name"
            param="name"
            label="Sklep użytkownika"
            placeholder="Wybierz sklep"
            fetch-one-url="/shop"
            :load-url="`/shop/${form.userId}/list/pagination`"
            :query-url="`/shop/${form.userId}/by-name`"
            :show-labels="false"
            :class="{ 'is-invalid': submitted && $v.form.shopId.$error }"
        />
        <div v-if="!$v.form.shopId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group" v-if="form.canExpire">
        <label>Czas trwania usługi (w dniach)</label>
        <input v-model.number="form.time" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.form.time.$error }" />
        <div v-if="!$v.form.time.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox mb-3">
          <input id="can-expire" v-model="form.canExpire" type="checkbox" class="custom-control-input" />
          <label for="can-expire" class="custom-control-label">Czy usługa może wygasnąć?</label>
        </div>
      </div>
      
      <div class="text-center">
        <b-button variant="primary" @click="createFreeOrder">Stwórz</b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import axios from "axios";
import Swal from "sweetalert2";
import {swalHelper} from "@/helpers/swal-helper";
import {errorCatcher} from "@/helpers/error-catcher";

export default {
  data() {
    return {
      submitted: false,
      visibleModal: false,
      callback: null,
      
      products: null,
      variations: null,

      form: {
        userId: "",
        shopId: "",
        variationId: "",
        variation: null,
        time: 30,
        canExpire: true
      },

      showOnlyType: ""
    }
  },

  validations() {
    const baseValidations = {
      userId: {required},
      variationId: {required},
    }

    let shopValidations = {}
    if (this.shouldSelectShop()) {
      shopValidations = {
        shopId: {required}
      }
    }

    if (this.form.canExpire) {
      return {
        form: {
          ...baseValidations,
          ...shopValidations,
          time: {required}
        }
      }
    }

    return {
      form: {
        ...baseValidations,
        ...shopValidations
      }
    }
  },

  methods: {
    async openModal(callback, showOnlyType = null, userId = null, shopId = null) {
      this.visibleModal = true
      this.callback = callback

      if (showOnlyType) {
        this.showOnlyType = showOnlyType
      }

      if (userId) {
        this.form.userId = userId
      }

      if (shopId) {
        this.form.shopId = shopId
      }

      await this.loadModalProducts()
    },

    hideModal() {
      this.visibleModal = false
      this.form.userId = ""
      this.form.variation = null
      this.form.variationId = ""
      this.form.time = 30
      this.form.shopId = ""
      this.form.canExpire = true
      this.callback = null
    },

    async loadModalProducts() {
      try {
        const { data } = await axios.get(`/marketplace/product/list`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.products = data
        if (this.showOnlyType) {
          this.products = this.products.filter(element => element.type === this.showOnlyType)
        }

        await this.loadModalProductVariations()
      } catch (error) {
        console.log(error)
      }
    },

    async loadModalProductVariations() {
      try {
        const { data } = await axios.get(`/marketplace/product/variation/list/all`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.variations = data.sort((a, b) => a.value - b.value)

        if (this.showOnlyType) {
          this.variations = this.variations.filter(variation =>
              this.products.some(product => product.id === variation.marketplaceProductId)
          );

          this.form.variation = this.variations[0]
          this.form.variationId = this.form.variation.id
        }
      } catch (error) {
        console.log(error)
      }
    },

    shouldSelectShop() {
      if (!this.form.variation) {
        return false
      }

      const marketplaceProduct = this.getMarketplaceProduct(this.form.variation.marketplaceProductId)
      if (!marketplaceProduct) {
        return false
      }

      return marketplaceProduct.type === "SHOP_PRODUCT_LIMIT_INCREASE"
          || marketplaceProduct.type === "WORDPRESS_PLUGIN"
    },

    createFreeOrder() {
      this.submitted = true

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      if (!this.form.userId) {
        Swal.fire("", "Wybierz użytkownika", "error");
        return
      }

      if (!this.form.variationId) {
        Swal.fire("", "Wybierz wariacje produktu", "error");
        return
      }

      swalHelper.yesOrNo(() => {
        let metadata = {}
        if (this.shouldSelectShop()) {
          metadata = {
            "metadata": JSON.stringify({
              "shopId": this.form.shopId
            })
          }
        }

        const json = JSON.stringify({
          order: {
            userId: this.form.userId,
            expiresAt: new Date().getMilliseconds() + (this.form.time * 24 * 60 * 60 * 1000), // przeliczanie z dni na ms
            canExpire: this.form.canExpire,
            ...metadata
          },

          marketplaceProductVariationId: this.form.variationId
        })

        axios.post(`/marketplace/order/manual-order`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          const callback = this.callback

          this.hideModal()
          Swal.fire("Sukces!", 'Pomyślnie utworzono darmowe zamówienie', "success").then(() => {
            if (callback) {
              callback()
            }
          })
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      });
    },

    selectVariation(variation) {
      this.form.variation = variation
      this.form.variationId = variation.id
    },

    getMarketplaceProduct(id) {
      for (const marketplaceProduct of this.products) {
        if (marketplaceProduct.id === id) {
          return marketplaceProduct
        }
      }

      return null
    },

    getVariationLabel(variation) {
      const marketplaceProduct = this.getMarketplaceProduct(variation.marketplaceProductId)

      let value = ""
      if (marketplaceProduct) {
        value += marketplaceProduct.name
        value += " o " + variation.title
      } else {
        value += variation.title
      }

      // value += " " + variation.priceWithTax.toFixed(2) + currencyHelper.getCurrencyInfo(variation.currency).symbol

      return value
    }

  }

}
</script>